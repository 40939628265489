const data = {
  title: 'Features',
  cards: [
    {
      imageUrl:
        'https://mfg-home.b-cdn.net/unkilled/newWeb/images/features/customization.webp',
      alt: 'customize character',
      title: 'Customize',
      text: "Personalize your gaming experience with many different character skins! Impress your opponent with your fashion sense. Just don't let the filthy zombies bleed all over your new dress :)",
    },
    {
      imageUrl:
        'https://mfg-home.b-cdn.net/unkilled/newWeb/images/features/campaigns.webp',
      alt: 'campaigns',
      title: 'Campaigns',
      text: 'Play the unique set of missions, collect as many stars as you can and earn you reward!',
    },
    {
      imageUrl:
        'https://mfg-home.b-cdn.net/unkilled/newWeb/images/features/warfares.webp',
      alt: 'warfares',
      title: 'Warfares',
      text: 'Prepare yourself for a new challenge! Wolfpak Warfares will test your skills in a bunch of disciplines. Are you more accurate than others? Do you know how to kill as much zombies as possible with explosives?',
    },
  ],
  sliderData: [
    {
      name: 'jade',
      imageUrl:
        'https://mfg-home.b-cdn.net/unkilled/newWeb/images/characters/4.webp',
      desc: [
        'Mysterious Ninja',
        'Possibly Good At Sneaking',
        'Likes Knives, I Guess',
        'A Silent Type... Probably',
      ],
    },
    {
      name: 'joe',
      imageUrl:
        'https://mfg-home.b-cdn.net/unkilled/newWeb/images/characters/5.webp',
      desc: [
        'SpecOps veteran',
        'Expert in all NATO weapon systems',
        'Collects comics',
        'Punched out a reporter once in Afghanistan',
      ],
    },
    {
      name: 'clark',
      imageUrl:
        'https://mfg-home.b-cdn.net/unkilled/newWeb/images/characters/1.webp',
      desc: [
        'Shotgun specialist',
        'Huge fan of weapon modifications, add-ons, doodads',
        'Really terrible singer',
        'Refuses to use public restrooms because of germs',
      ],
    },
    {
      name: 'survivor',
      imageUrl:
        'https://mfg-home.b-cdn.net/unkilled/newWeb/images/characters/2.webp',
      desc: [
        'Hacking expert',
        'Likes knives, and things with a razor sharp edge',
        'Addicted to Sriracha sauce',
        'Avoids phone calls like the plague',
      ],
    },
    {
      name: 'sam',
      imageUrl:
        'https://mfg-home.b-cdn.net/unkilled/newWeb/images/characters/6.webp',
      desc: [
        'Former Special Agent for the FBI',
        'Exceptional long-range sniper',
        'Intense hatred for rap music',
        'Collects bobble-heads',
      ],
    },
    {
      name: 'u.s. ranger',
      imageUrl:
        'https://mfg-home.b-cdn.net/unkilled/newWeb/images/characters/3.webp',
      desc: [
        'Likes things that go BOOM!',
        'Married four times',
        'Has a grim sense of humor',
        'Prefers fieldstripping a weapon to small talk',
      ],
    },
  ],
}

export default data
