import React from 'react'
import Card from './Card'
import data from './KeyFeaturesSectionData'
import Slider from './Slider'
import * as styles from './Sections.module.css'

const KeyFeaturesSection = (props) => {
  const { title, cards, sliderData } = data

  return (
    <div>
      <div className={styles.sectionTitle}>{title}</div>
      <div className={styles.cardsContainer}>
        {cards.map((cardData, index) => {
          return <Card cardData={cardData} key={index} />
        })}
      </div>
      <div className={styles.flamesBg}>
        <Slider sliderData={sliderData} reversed={true} />
      </div>
    </div>
  )
}

export default KeyFeaturesSection
