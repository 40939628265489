import React from 'react'
import * as styles from './Sections.module.css'
import Card from './Card'
import data from './GamemodesSectionData'
import Slider from './Slider'

const GamemodesSection = (props) => {
  const { title, cards, sliderData } = data
  return (
    <div>
      <div className={styles.sectionTitle}>{title}</div>
      <div className={styles.cardsContainer}>
        {cards.map((cardData, index) => {
          return <Card cardData={cardData} key={index} />
        })}
      </div>
      <div className={styles.texturedBg}>
        <Slider sliderData={sliderData} />
      </div>
    </div>
  )
}

export default GamemodesSection
