import React from 'react'
import Layout from '../components/Layout'
import Main from '../components/main/Main'
import Video from '../components/video/Video'
import {
  KeyFeaturesSection,
  GamemodesSection,
} from '../components/slider_section'
import WeaponShowcase from '../components/weapons/WeaponShowcase'

const CDN_ROOT = 'https://mfg-home.b-cdn.net/'

const App = () => (
  <Layout CDN_ROOT={CDN_ROOT}>
    <Main CDN_ROOT={CDN_ROOT} />
    <KeyFeaturesSection CDN_ROOT={CDN_ROOT} />
    <Video CDN_ROOT={CDN_ROOT} />
    <GamemodesSection CDN_ROOT={CDN_ROOT} />
    <WeaponShowcase CDN_ROOT={CDN_ROOT} />
  </Layout>
)

export default App
