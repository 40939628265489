import React from 'react'
import * as styles from './Video.module.css'

const Video = ({ CDN_ROOT }) => {
  return (
    <div className={styles.video}>
      <div className={styles.sectionTitle}>{'Trailer'}</div>
      <video
        src={`${CDN_ROOT}unkilled/videos/header/2/video-1910.webm`}
        poster={`${CDN_ROOT}unkilled/videos/video-placeholder.png`}
        controls></video>
    </div>
  )
}

export default Video
