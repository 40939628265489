import React, { useState } from 'react'
import * as styles from './Sections.module.css'
import Img from '../Img'

const Card = ({ cardData }) => {
  const [isDisplayingDescription, setIsDisplayingDescription] = useState(false)
  return (
    <div
      className={styles.card}
      onClick={() => setIsDisplayingDescription(!isDisplayingDescription)}>
      <Img
        src={cardData.imageUrl}
        alt={cardData.alt}
        className={styles.cardImg}
        fallback={'.jpg'}
      />
      <div
        className={
          isDisplayingDescription ? styles.gradientBgDark : styles.gradientBg
        }></div>
      {isDisplayingDescription ? (
        <div className={styles.cardText}>{cardData.text}</div>
      ) : (
        <div className={styles.cardTitle}>
          {cardData.title}
          <br /> <span>Read more </span>
        </div>
      )}
    </div>
  )
}

export default Card
