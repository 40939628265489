import React from 'react'
import * as styles from './Dots.module.css'

function Dot(props) {
  const { active } = props
  return (
    <div onClick={props.onClick}>
      <div className={active ? styles.dotActive : styles.dotInactive}></div>
    </div>
  )
}

export default Dot
