import React from 'react'
import { SendRight } from '.'

const SendLeft = ({ fill }) => {
  return (
    <div style={{ transform: 'rotate(180deg)' }}>
      <SendRight fill={fill} />
    </div>
  )
}

export default SendLeft
