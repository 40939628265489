import React from 'react'
import { SendRight } from '../icons'
import * as styles from './Weapons.module.css'
import Img from '../Img'

function WeaponTile({ rarity, imgUrl, CDN_ROOT, href }) {
  return (
    <div className={styles[rarity]}>
      <a href={href}>
        <div className={styles.spacedRow}>
          <h2 className={styles.title}>{rarity}</h2>
          <SendRight />
        </div>
        <div className={styles.weaponPosition}>
          <div className={styles.tilted}>
            <Img
              src={CDN_ROOT + imgUrl}
              alt={'weapon ' + rarity}
              fallback={'.png'}
            />
          </div>
        </div>
      </a>
    </div>
  )
}

export default WeaponTile
