// extracted by mini-css-extract-plugin
export var sliderContainer = "Slider-module--sliderContainer--1Ik2t";
export var sliderBody = "Slider-module--sliderBody--3w1T0";
export var content = "Slider-module--content--WUfHR";
export var reversedContent = "Slider-module--reversedContent--20QRH";
export var dotsContent = "Slider-module--dotsContent--22BSE";
export var descriptionContent = "Slider-module--descriptionContent--3EHYu";
export var imageContent = "Slider-module--imageContent--3SZnx";
export var smallerDesc = "Slider-module--smallerDesc--3qpRl";
export var biggerDesc = "Slider-module--biggerDesc--Oyw1H";
export var arrowLeft = "Slider-module--arrowLeft--2l84O";
export var arrowRight = "Slider-module--arrowRight--2SDwY";
export var dotsContainer = "Slider-module--dotsContainer--3JNeC";