const data = {
  title: 'Game modes',
  cards: [
    {
      imageUrl:
        'https://mfg-home.b-cdn.net/unkilled/newWeb/images/enemy/singleplayer.webp',
      alt: 'singleplayer',
      title: 'singleplayer',
      text: 'Play through the story of the Wolfpack’s epic journey through NYC during the Zombie outbreak epidemic. Cut a swathe through hordes of brain-eaters and unique boss critters.',
    },
    {
      imageUrl:
        'https://mfg-home.b-cdn.net/unkilled/newWeb/images/enemy/skirmish.webp',
      alt: 'skirmish-ops',
      title: 'skirmish ops',
      text: 'Attack other players’ bases with your zombie army and defend your to fire up through the leaderboard. Your base is also a very good source of GOLD and INTEL.',
    },
    {
      imageUrl:
        'https://mfg-home.b-cdn.net/unkilled/newWeb/images/enemy/multiplayer.webp',
      alt: 'multiplayer',
      title: 'multiplayer',
      text: 'Choose your hero and fight against real opponents in the special PvP arenas. Promote your favorite character and use unique gadgets and abilities to gain an advantage over your opponent. become the champion and win prizes in tournaments.',
    },
  ],
  sliderData: [
    {
      name: 'sheriff',
      imageUrl:
        'https://mfg-home.b-cdn.net/unkilled/newWeb/images/enemies/sheriff.webp',
      desc: ['Special ability: shockwave punch', 'Weakness: big, juicy target'],
    },
    {
      name: 'dodger',
      imageUrl:
        'https://mfg-home.b-cdn.net/unkilled/newWeb/images/enemies/dodger.webp',
      desc: ['Special ability: berserker sprint', 'Weakness: incomplete armor'],
    },
    {
      name: 'minesweeper',
      imageUrl:
        'https://mfg-home.b-cdn.net/unkilled/newWeb/images/enemies/minesweeper.webp',
      desc: [
        'Special ability: mine detonation',
        'Weakness: explosions also kill fellow zombies',
      ],
    },
    {
      name: 'butcher',
      imageUrl:
        'https://mfg-home.b-cdn.net/unkilled/newWeb/images/enemies/butcher.webp',
      desc: [
        'Special ability: blade-hand, blood-lust',
        'Weakness: ineffective in medium and long range',
      ],
    },
    {
      name: 'nukleon',
      imageUrl:
        'https://mfg-home.b-cdn.net/unkilled/newWeb/images/enemies/nukleon.webp',
      desc: [
        'Special ability: radioactive aura',
        'Weakness: easy target from medium and long distance',
      ],
    },
  ],
}

export default data
