import React from 'react'
import WeaponTile from './WeaponTile'
import * as styles from './Weapons.module.css'

const weapons = [
  {
    rarity: 'common',
    imgUrl: 'unkilled/newWeb/images/weapons/common.webp',
    href: '/weapons/common',
  },
  {
    rarity: 'uncommon',
    imgUrl: 'unkilled/newWeb/images/weapons/uncommon.webp',
    href: '/weapons/uncommon',
  },
  {
    rarity: 'rare',
    imgUrl: 'unkilled/newWeb/images/weapons/rare.webp',
    href: '/weapons/rare',
  },
  {
    rarity: 'epic',
    imgUrl: 'unkilled/newWeb/images/weapons/epic.webp',
    href: '/weapons/epic',
  },
]

const WeaponShowcase = ({ CDN_ROOT }) => {
  return (
    <div className={styles.bg}>
      <div className={styles.titleWrap}>
        <div className={styles.title2}>Weapons</div>
      </div>
      <div className={styles.weaponWrapper}>
        {weapons.map((weapon, index) => {
          return (
            <WeaponTile
              rarity={weapon.rarity}
              imgUrl={weapon.imgUrl}
              href={weapon.href}
              CDN_ROOT={CDN_ROOT}
              key={weapon.rarity + index}
            />
          )
        })}
      </div>
    </div>
  )
}

export default WeaponShowcase
