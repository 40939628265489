import React, { useState } from 'react'
import Dot from './Dot'
import * as styles from './Dots.module.css'

function DotStrip(props) {
  const { count, activeIndex } = props

  const onDotClick = (index) => {
    if (index === activeIndex) {
      return
    }
    props.onDotChange && props.onDotChange(index)
  }

  return (
    <div className={styles.stripContainer}>
      {[...Array(count).keys()].map((index) => (
        <Dot
          active={index === activeIndex}
          key={index}
          onClick={() => onDotClick(index)}
        />
      ))}
    </div>
  )
}

export default DotStrip
