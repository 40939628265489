import React from 'react'
import * as styles from './Main.module.css'
import Img from '../Img'

const Main = ({ CDN_ROOT }) => {
  return (
    <div className={styles.mainCenter}>
      <div className={styles.mainImg}>
        <Img
          src={CDN_ROOT + 'unkilled/newWeb/images/mainimg.webp'}
          alt='Unkilled 5 years'
          fallback={'.jpg'}
        />
        <div className={styles.storePosition}>
          <a
            name='uk-gplay'
            href={`https://play.google.com/store/apps/details?id=com.madfingergames.unkilled`}>
            <Img
                src={CDN_ROOT + 'shared/stores/gplay.png'}
                alt='unkilled gplay store'
            />
          </a>
          <a
            name='uk-appstore'
            href='https://apps.apple.com/nz/app/unkilled-zombie-fps-shooter/id969488951'>
            <Img
                src={CDN_ROOT + 'shared/stores/appstore.png'}
                alt='unkilled apple store'
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Main
