// extracted by mini-css-extract-plugin
export var sectionTitle = "Sections-module--sectionTitle--Tv9Vu";
export var sectionSubtitle = "Sections-module--sectionSubtitle--3tK9p";
export var cardsContainer = "Sections-module--cardsContainer--1YYcc";
export var card = "Sections-module--card--1tGGr";
export var gradientBg = "Sections-module--gradientBg--3MhOh";
export var gradientBgDark = "Sections-module--gradientBgDark--1jfzy";
export var cardImg = "Sections-module--cardImg--lSp8z";
export var cardTitle = "Sections-module--cardTitle--ra1e4";
export var cardText = "Sections-module--cardText--xgQHT";
export var flamesBg = "Sections-module--flamesBg--3Uwij";
export var texturedBg = "Sections-module--texturedBg--2jS04";