// extracted by mini-css-extract-plugin
export var bg = "Weapons-module--bg--2M9f3";
export var titleWrap = "Weapons-module--titleWrap--1XbQZ";
export var title = "Weapons-module--title--3vXpA";
export var title2 = "Weapons-module--title2--1hxP9";
export var subtitle = "Weapons-module--subtitle--3hly4";
export var weaponWrapper = "Weapons-module--weaponWrapper--28KRU";
export var spacedRow = "Weapons-module--spacedRow--1IPD5";
export var tilted = "Weapons-module--tilted--3zI3h";
export var common = "Weapons-module--common--16vcp";
export var uncommon = "Weapons-module--uncommon--2ohGY";
export var rare = "Weapons-module--rare--36WvB";
export var epic = "Weapons-module--epic--2MQjC";
export var weaponPosition = "Weapons-module--weaponPosition--84A0p";