import React, { useState } from 'react'
import DotStrip from './dots/DotStrip'
import * as styles from './Slider.module.css'
import { SendLeft, SendRight } from '../icons'
import Img from '../Img'

const Slider = (props) => {
  const { sliderData, reversed = false } = props

  const [activeIndex, setActiveIndex] = useState(0)

  const onDotChange = (newIndex) => {
    setActiveIndex(newIndex)
  }
  const currentSlide = sliderData[activeIndex]
  return (
    <div className={styles.sliderContainer}>
      <div className={styles.sliderBody}>
        <div
          className={styles.arrowLeft}
          onClick={() =>
            setActiveIndex(
              (activeIndex + sliderData.length - 1) % sliderData.length,
            )
          }>
          <SendLeft />
        </div>
        <div className={reversed ? styles.reversedContent : styles.content}>
          {/* Text and dots */}
          <div className={styles.dotsContent}>
            <div className={styles.descriptionContent}>
              <h1>{currentSlide.name}</h1>
              {currentSlide.desc.map((detail, index) => (
                <div
                  className={
                    index % 2 === 1 ? styles.smallerDesc : styles.biggerDesc
                  }
                  key={'slider' + index}>
                  {detail}
                </div>
              ))}
            </div>
            <div className={styles.dotsContainer}>
              <DotStrip
                count={sliderData.length}
                activeIndex={activeIndex}
                onDotChange={onDotChange}
              />
            </div>
          </div>
          {/* Image */}
          <div className={styles.imageContent}>
            <Img
              src={currentSlide.imageUrl}
              alt={currentSlide.name + ' image'}
              fallback={'.png'}
            />
          </div>
        </div>
        <div
          className={styles.arrowRight}
          onClick={() => setActiveIndex((activeIndex + 1) % sliderData.length)}>
          <SendRight />
        </div>
      </div>
    </div>
  )
}

export default Slider
