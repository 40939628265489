import React from 'react'

function SendRight({ fill }) {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.67502 17.5L19.1667 10L1.67502 2.5L1.66669 8.33333L14.1667 10L1.66669 11.6667L1.67502 17.5Z'
        fill={fill || '#F3F7FA'}
      />
    </svg>
  )
}

export default SendRight
